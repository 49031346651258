import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Swal from "sweetalert2";
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { TabList,Tab,TabContext,TabPanel } from '@mui/lab';

import axios from 'axios';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';


function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData(1, 'Cupcake', 305, 3.7, 67, 4.3),
  createData(2, 'Donut', 452, 25.0, 51, 4.9),
  createData(3, 'Eclair', 262, 16.0, 24, 6.0),
  createData(4, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData(5, 'Gingerbread', 356, 16.0, 49, 3.9),
  createData(6, 'Honeycomb', 408, 3.2, 87, 6.5),
  createData(7, 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData(8, 'Jelly Bean', 375, 0.0, 94, 0.0),
  createData(9, 'KitKat', 518, 26.0, 65, 7.0),
  createData(10, 'Lollipop', 392, 0.2, 98, 0.0),
  createData(11, 'Marshmallow', 318, 0, 81, 2.0),
  createData(12, 'Nougat', 360, 19.0, 9, 37.0),
  createData(13, 'Oreo', 437, 18.0, 63, 4.0),
];













export default function MessageTable() {
  
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open1, setOpen1] = useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        
      }
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const [data , setData] = React.useState([])


  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(null);
  const [ loading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)
    handleOpendr()
    axios
      .get(`https://emedha.com/wcru/count.php?start=${startDate}&end=${endDate}`)
      .then((response) => {
        setTableData(response.data.analytics.data_points); 
        console.log("paramdarta", response.data.analytics.data_points);
        setLoading(false)
      })
      .catch((error) => {
        setError('Error fetching data');
        setLoading(false)
        console.error('Error fetching data:', error);
      });
  };

  // -----------------------------

  const [present, setPresent] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const presentDate = `${year}-${month}-${day}`; 
    setPresent(presentDate);
  }, []);

  useEffect(() => {
    console.log("Present Value:", present);
  }, [present]);



  const [msgcount, setMsgcount] = useState()
  useEffect(() => {
    const apiUrl = `https://emedha.com/wcru/count.php?start=2023-12-01&end=${present}`;
    axios.get(apiUrl)
    .then(response => {
      setMsgcount(response.data.analytics.data_points.map((item)=> item.sent).reduce((acc, curr) => acc + curr, 0));
      console.log("count",response.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  // --------------------------

  const handleSubmit = (event) => {
    event.preventDefault();
    if (startDate && endDate) {
      fetchData();
      setOpen(false)
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const [opendr, setOpendr] = React.useState(false);
  const handleClosedr = () => {
    setOpendr(false);
  };
  const handleOpendr = () => {
    setOpendr(true);
  };


  

 
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper >
              <Typography sx={{ p: 2 }}></Typography>
              <div className='card' style={{ height: 170, width: 410, cursor: 'auto', border: 'none', position: 'relative', padding: 10 }} >
                                                {/* <h5 style={{ textAlign: 'start', marginTop: 10 }} >Select date to the filter</h5> */}
                                                {/* <input  type='date' style={{ outline: 'none', border: 'none', borderBottom: '3px solid rgb(58, 158, 58)', marginTop: 35 ,marginLeft:30,width:'50%'}} /> */}
                                                <form  style={{display:'flex',justifyContent:"space-evenly" }}>
                                                <div>
                                                <label htmlFor="start-date" style={{display:'block',marginBottom:15}}>Start Date:</label>
                                                <input type="date" id="start-date" value={startDate} style={{padding:10}} onChange={handleStartDateChange} />
                                                </div>
                                              
                                                <div>
                                                <label htmlFor="end-date" style={{display:'block',marginBottom:15}}>End Date:</label>
                                                <input type="date" id="end-date" value={endDate} style={{padding:10}} onChange={handleEndDateChange} />
                                                </div>
                                              
                                                {/* <button type="submit">Submit</button> */}
                                              </form>
                    
      
                                                        {/* <button style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(58, 158, 58)', position: 'absolute', right: 10, bottom: 10, border: 'none', borderRadius: '0.2rem', width: '5rem', height: 35 }} onClick={handleClickOpen}>Filter</button> */}
                                                        <button style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(58, 158, 58)', position: 'absolute', right: 10, bottom: 10, border: 'none', borderRadius: '0.2rem', width: '5rem', height: 35 }} onClick={handleSubmit}>submit</button>
                                                <button onClick={() => setOpen(false)} style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'red', position: 'absolute', right: 130, bottom: 10, border: 'none', borderRadius: '0.2rem', width: '5rem', height: 35 }} >Cancel</button>
                                            </div>
            </Paper>
          </Fade>
        )}
      </Popper>

      
          <Button variant="contained" endIcon={<ArrowDropDownIcon />} onClick={handleClick('bottom-start')} style={{marginTop:20,marginBottom:30,marginLeft:10,backgroundColor: 'red', color: 'white'}} >
            Select by date
          </Button>

          <Button variant="outlined" style={{marginLeft:20}} >
          Total  : 50,000
      </Button>

          <Button variant="outlined" style={{marginLeft:20}} >
          Total used : {msgcount}
      </Button>
         
       <TableContainer>

      
   
      
        <Table >
           
            <TableHead sx={{ backgroundColor: 'lightgray' }}>
              <TableRow>
               
                <TableCell
                  align={"center"}
                  style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
                >
                  From Date - To Date
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Sent
                </TableCell>
                <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Delivered
                </TableCell>
                {/* <TableCell

                  align={"center"}
                  style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
                >
                  Action
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
           
             {
              tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow   tabIndex={-1} key={index}>
                      
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 30, fontSize: 12 }}>{`${new Date(row.start * 1000).toLocaleDateString()} - ${new Date(row.end * 1000).toLocaleDateString()}`}</TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}><button style={{ height: 20, color: 'white',  borderRadius: '0.4rem', border: 'none', paddingLeft: 10, paddingRight: 10, backgroundColor: 'green' }} >{row.sent}</button></TableCell>
                      <TableCell align={"center"}
                        style={{ top: 57, minWidth: 87, fontSize: 12 }}><button style={{ height: 20, color: 'white',  borderRadius: '0.4rem', border: 'none', paddingLeft: 10, paddingRight: 10, backgroundColor: 'blue' }} >{row.delivered}</button></TableCell>
                      

                    </TableRow>
              ))
             }

            </TableBody>

            {/* {
              tableData.length === 0 && (
                <center>
                  <div >
                  <h1 style={{color:'grey'}}>NO DATA FOUND FOR SELECTED DATES</h1>
                </div>
                </center>
              )
            } */}
            
            
        </Table>

        {
                loading && 
                <>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={opendr}
                onClick={handleClosedr}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              </> 
                
                }

       </TableContainer>
        {
          tableData.length !== 0 && (
            <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
          )
        }
      </Paper>

      <div>
     
      </div>
      
    </Box>
  );
}