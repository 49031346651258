import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {  InputBase, Button } from '@mui/material';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ApprovedTemplate() {
    const [name, setName] = useState('')
    const [data, setData] = useState([])
    const[tab, setTab] = useState([])
    const handlesubmit = (name) => {
        handleClickOpen()
        axios.post(`https://emedha.com/wcru/get-template.php?name=${name}`)
        .then(res => {
            console.log(res.data.data[0]);
            setData(res.data.data[0])

        })
    }

    useEffect(()=>{
        axios.post(`https://emedha.com/wcru/get-template-status.php?limit=10`)
        .then(res => {
            console.log(res.data.data);
            setTab(res.data.data)

        })
    },[])

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between',marginBottom:5 }}>
        <InputBase
              sx={{ width: '70%',padding:1, mr: 5 }}
              placeholder="Search..."
              onChange={(e)=>setName(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handlesubmit} style={{width:'10%'}}>
              View
            </Button>
        </Box> */}

        <Box sx={{marginTop:15}} >
        <TableContainer>

      
   
      
<Table >
   
    <TableHead sx={{ backgroundColor: 'lightgray' }}>
      <TableRow>
       
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Id
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Name
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Status
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Action
        </TableCell>
        
      </TableRow>
    </TableHead>
    <TableBody>
    {
        tab.map(item => (
            <TableRow tabIndex={-1} key={item.id}>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {item.id}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {item.name}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {item.status}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            
            <Button variant="contained" onClick={() => handlesubmit(item.name)} color="success">
  View
</Button>
          </TableCell>
        </TableRow>
        ))
    }
    </TableBody>
    
    </Table>
    </TableContainer>
        </Box>
      </Paper>
      </Box>

      <React.Fragment>
      <Dialog
        open={open}
        maxWidth='lg'
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <TableContainer>

      
   
      
<Table >
   
    <TableHead sx={{ backgroundColor: 'lightgray' }}>
      <TableRow>
       
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Id
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Name
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Type
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          text
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Category
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Language
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Status
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Body Text
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
        <TableRow tabIndex={-1} key={data.id}>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.id}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.name}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.components?.[0]?.type}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 87, fontSize: 12 }}>
            {data.components?.[0]?.text}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.category}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.language}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 87, fontSize: 12 }}>
            <button
              style={{
                height: 20,
                color: 'white',
                borderRadius: '0.4rem',
                border: 'none',
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: 'green',
              }}
            >
              {data.status}
            </button>
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.components?.[0]?.example?.body_text?.[0]?.join(', ')}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    </div>
  )
}
