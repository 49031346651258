import logo from './logo.svg';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import Login from './Components/Login/Login';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div >
      {
        !sessionStorage.getItem('log') && (
         <Login/>
       
        )
      }
      {
       sessionStorage.getItem('log') && (
          
          <Dashboard/>
        
        )
      }
    
    </div>
  );
}

export default App;
