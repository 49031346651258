import React, { useState } from 'react';
import styled from 'styled-components';
import dr from '../images/wcrelog.jpeg'


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Form = styled.form`
  width: 300px;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  
`;

const FormField = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 95%;
    padding: 8px;
    border: 1px solid #y;
    border-radius: 4px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ForgotPassword = styled.div`
  text-align: center;
  margin-top: 10px;
  color: #b347bf;
  cursor: pointer;
`;


export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
      const handleSubmit = (e) => {
      e.preventDefault();
  
      if (email === 'wcreu@gmail.com' && password === '321') {
        
       sessionStorage.setItem('log','w345esft3iu34u20394302942u3ji')
       window.location.reload()
      } else {
        alert('Email or password is incorrect.');
      }
   };
  
  return (
    <Container>
    <Form onSubmit={handleSubmit}>
      <center><img src={dr} height='150px' width='165px'></img></center>
      
      <Title>Login</Title>
      <FormField>
        <label>Email:</label>
        <input type="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
      </FormField>
      <FormField>
        <label>Password:</label>
        <input type="password" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} />
      </FormField>
      <SubmitButton type="submit">Submit</SubmitButton>
      {/* <ForgotPassword>Forgot Password?</ForgotPassword> */}
    </Form>
  </Container>
  )
}
